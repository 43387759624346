<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>Sub Category List</h4>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showModal"
        >
          New
        </b-button>
      </div>
      <div class="mb-2">
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Filter By Categories"
              label-for="v-category"
            >
              <b-form-select
                id="h-category"
                v-model="active_category"
                :options="categories_options"
                @change="filterCategory"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Search by keyword"
              label-for="v-search-keyword"
            >
              <b-form-input
                id="h-search-keyword"
                v-model="filter"
                type="text"
                placeholder="Search by keyword..."
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive="sm"
        :items="sub_categories"
        :fields="fields"
        :per-page="perPage"
        sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        :current-page="currentPage"
        @row-clicked="showDetails"
      >
        <template
          #cell(parent_id)="data"
        >
          {{ data.value === null || data.value === '' ? '' : categories && categories.filter(item => item.id === data.value)[0].name }}
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="right"
        size="sm"
        class="my-0"
      />
    </b-card>

    <b-modal
      id="app-modal"
      :title="is_update ? 'Uptate' : 'Add New'"
      hide-footer
      @hide="hide"
    >
      <b-card-text>
        <validation-observer ref="subCategoryForm">
          <b-form @submit.prevent="is_update ? updateSubCategory() : createSubCategory()">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Categories"
                  label-for="v-category"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="category"
                    rules="required"
                  >
                    <b-form-select
                      id="h-category"
                      v-model="sub_category.parent_id"
                      :state="errors.length > 0 ? false:null"
                      :options="categories_options"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- first name -->
              <b-col cols="12">
                <b-form-group
                  label="Sub Category Name"
                  label-for="v-sub-category-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="v-sub-category"
                      v-model="sub_category.name"
                      placeholder="Sub Category"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>
            <div class="mt-4 d-flex align-items-center justify-content-between">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>

              <b-button
                v-if="is_update"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                @click="deleteData"
              >
                Delete
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                type="submit"
              >
                Save
              </b-button>
            </div>
          </b-form>
        </validation-observer>

      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BPagination, BTable, BRow, BCol, BFormGroup, BFormInput, BForm, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    BFormSelect,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      perPage: 10,
      pageOptions: [3, 5, 10],
      currentPage: 1,
      fields: [{ key: 'parent_id', label: 'Category' }, { key: 'name', label: 'Sub Category Name', sortable: true }, 'naics'],
      sub_category: { name: '', naics: '', parent_id: 1 },
      required,
      active_category: 1,
      filter: null,
      sortBy: 'id',
      sortDesc: false,
      filterOn: [],
    }
  },
  computed: {
    categories() {
      return this.$store.state.category.categories
    },
    sub_categories() {
      return this.$store.state.subCategory.sub_categories
    },
    categories_options() {
      return this.categories.map(item => {
        const data = { value: item.id, text: item.name }
        return data
      })
    },
    totalRows() {
      return this.sub_categories.length
    },
  },
  watch: {
    '$store.state.subCategory.all_sub_categories': {
      handler() {
        this.getSubCategory()
      },
      deep: true,
    },
  },
  mounted() {
    this.getSubCategory()
  },
  methods: {
    getSubCategory() {
      return this.$store.dispatch('subCategory/getSubCategories', this.active_category)
    },
    filterCategory() {
      return this.$store.dispatch('subCategory/getSubCategories', this.active_category)
    },
    createSubCategory() {
      this.$refs.subCategoryForm.validate().then(success => {
        if (success) {
          const data = this.sub_category
          data.naics = this.categories.filter(item => item.id === data.parent_id)[0].naics
          this.$store.dispatch('subCategory/createSubCategory', data)
          this.hide()
        }
      })
    },
    updateSubCategory() {
      this.$refs.subCategoryForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('subCategory/updateSubCategory', this.sub_category)
          this.hide()
        }
      })
    },
    deleteData() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('subCategory/deleteSubCategory', this.sub_category)
          this.hide()
        }
      })
    },
    emptyData() {
      this.sub_category = { name: '', naics: '', parent_id: 1 }
    },
    showModal() {
      this.$bvModal.show('app-modal')
    },
    hide() {
      this.is_update = false
      this.emptyData()
      this.$bvModal.hide('app-modal')
    },
    showDetails(item) {
      this.is_update = true
      this.sub_category = item
      this.showModal()
    },
  },
}
</script>

  <style>

  </style>
